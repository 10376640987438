<template>
  <div class="order-detials">
    <!-- 空状态 -->
    <el-empty v-if="showdesc" :image-size="200" :description="description"></el-empty>
    <div v-else class="content">
      <el-row>
        <el-col :span="4">店铺平台：{{ detialData.shop }}</el-col>
        <el-col :span="5">订单号：{{ detialData.orderNo }}</el-col>
        <el-col :span="4">行程：{{ detialData.voyageType }}</el-col>
        <el-col :span="3">政策代码：{{ detialData.policyCode }}</el-col>
        <el-col :span="4">平台进单时间：{{ detialData.platformAddTime }}</el-col>
        <el-col :span="4">系统进单时间：{{ detialData.addTime }}</el-col>
      </el-row>
      <el-divider content-position="left"><i class="el-icon-s-promotion"></i> 航班信息</el-divider>
      <el-table
        :data="detialData.voyage"
        size="mini"
        style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }"
      >
        <el-table-column prop="carrier" label="航司" width="60"></el-table-column>
        <el-table-column prop="flightNo" label="航班号" width="100"></el-table-column>
        <el-table-column label=" 出发" width="230">
          <template slot-scope="scope">
            <div>{{ scope.row.dep }}</div>
            <!-- <div>{{ scope.row.dep.cnName }}</div>
            <div>{{ scope.row.dep.enName }}</div> -->
          </template>
        </el-table-column>
        <el-table-column label=" 到达" width="230">
          <template slot-scope="scope">
            <div>{{ scope.row.arr }}</div>
            <!-- <div>{{ scope.row.arr.cnName }}</div>
            <div>{{ scope.row.arr.enName }}</div> -->
          </template>
        </el-table-column>
        <el-table-column prop="depTime" label="起飞时间" width="140"></el-table-column>
        <el-table-column prop="arrTime" label="到达时间" width="140"></el-table-column>
        <el-table-column prop="cabin" label="舱位" width="140"></el-table-column>
        <el-table-column prop="chdCabin" label="儿童舱位" width="140"></el-table-column>
        <el-table-column
          prop="handBaggage"
          label="手提/托运行李额(KG)"
          width="140"
        ></el-table-column>
        <el-table-column prop="mainFlightNo" label="实际承运"></el-table-column>
      </el-table>
      <el-divider content-position="left"
        ><i class="fa fa-user-circle"></i> 乘客信息</el-divider
      >
      <el-table
        :data="detialData.passenger"
        size="mini"
        style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="passengerType" label="乘机人类型"></el-table-column>
        <el-table-column prop="certificateType" label="证件类型"></el-table-column>
        <el-table-column prop="certificateNumber" label="证件号" width="150">
          <template slot-scope="scope">
            <div>
              {{ scope.row.certificateNumber }}
              <el-button type="text" size="small" @click="Decrypt(scope.row)"
                >解密</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="birthday" label="生日" width="150"></el-table-column>
        <el-table-column prop="gender" label="性别"></el-table-column>
        <el-table-column label="姓-名">
          <template slot-scope="scope">
            <span>{{ scope.row.surName }}</span>
            <span>{{ scope.row.givenName }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="authority" label="发证国"></el-table-column>
        <el-table-column prop="country" label="国籍"></el-table-column>
        <el-table-column
          prop="dateOfExpiry"
          label="证件有效期"
          width="150"
        ></el-table-column>
        <el-table-column prop="pnr" label="编码">
          <template slot-scope="scope">
            <div class="on-input" v-if="argument.type === 1">{{ scope.row.pnr }}</div>
            <el-input
              v-else
              @focus="focusPnr(scope.row.pnr)"
              @blur="checkPnr(scope.row)"
              size="mini"
              v-model="scope.row.pnr"
            >
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-divider content-position="left"
        ><i class="el-icon-s-ticket"></i> 票价信息</el-divider
      >
      <el-table
        :data="detialData.price"
        :span-method="objectSpanMethod"
        size="mini"
        border
        cell-border
        row-border
        :header-cell-style="{ background: '#eee', color: '#555' }"
      >
        <el-table-column prop="type" label="旅客类型"></el-table-column>
        <el-table-column prop="price" label="销售价"></el-table-column>
        <el-table-column prop="face" label="票面价"></el-table-column>
        <el-table-column prop="tax" label="税费"></el-table-column>
        <el-table-column prop="count" label="人数"></el-table-column>
        <el-table-column prop="subtotalAmount" label="合计"></el-table-column>
        <el-table-column prop="insuranceFee" label="保险费"></el-table-column>
        <el-table-column prop="expressFee" label="快递费"></el-table-column>
        <el-table-column prop="totalAmount" label="总金额"></el-table-column>
      </el-table>
      <!-- 强制多排按钮 -->
      <div class="drawbutton" v-if="argument.type === 2">
        <el-switch style="margin-right: 10px" v-model="force" active-text="强制">
        </el-switch>
        <el-button
          v-for="(item, index) in buttonList"
          :key="index"
          @click="ForceButton(index)"
          size="small"
          >{{ item }}</el-button
        >
      </div>
      <!-- 强制选择框 -->
      <el-dialog
        title="编码"
        :visible.sync="dialogForce"
        :show-close="false"
        destroy-on-close
        :close-on-click-modal="false"
      >
        <el-form v-if="formattedData == ''" inline size="mini">
          <el-form-item label="Office">
            <el-select v-model="Generatelnstructionsvalue">
              <el-option
                v-for="(item, index) in AllOfficeList"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="乘机人">
            <el-select v-model="passengerData.passengerId" multiple>
              <el-option
                v-for="item in detialData.passenger"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button size="small" type="primary" @click="Generatel()">查询</el-button>
        </el-form>
        <div v-else>
          <el-input type="textarea" :rows="10" v-model="formattedData"></el-input>
          <el-button
            size="small"
            style="margin-top: 20px"
            type="primary"
            @click="dialogForce = false"
            >确认</el-button
          >
        </div>
      </el-dialog>
      <el-divider content-position="left"
        ><i class="el-icon-s-goods"></i> 采购信息
        <el-button
          v-if="argument.type === 2"
          @click="Newpurchase(true)"
          type="success"
          size="mini"
          style="margin-left: 20px"
          >新增采购</el-button
        ></el-divider
      >
      <el-table
        default-expand-all
        :data="detialData.ticketing"
        size="mini"
        style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }"
      >
        <!-- 其他列 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.detail"
              size="mini"
              style="width: 100%"
              :header-cell-style="{ background: 'rgb(175 204 221)', color: '#555' }"
            >
              <el-table-column prop="name" label="乘机人"></el-table-column>
              <el-table-column prop="ticketNo" label="票号">
                <template slot-scope="scope">
                  <div v-if="argument.type === 1" class="ticket-no">
                    {{ scope.row.ticketNo }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.ticketNo"
                    @focus="focusPnr(scope.row.ticketNo)"
                    @blur="checkTicketNo(scope.row, scope.$index)"
                    size="mini"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="票价"></el-table-column>
              <el-table-column prop="tax" label="税费"></el-table-column>
              <el-table-column prop="totalAmount" label="总价"></el-table-column>
              <el-table-column prop="voayge" label="行程"></el-table-column>
              <!-- 其他支付信息列 -->
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="采购渠道"></el-table-column>
        <el-table-column
          prop="purchOrderNo"
          label="采购单号"
          width="200"
        ></el-table-column>
        <el-table-column prop="paymentAmount" label="支付金额"></el-table-column>
        <el-table-column prop="discountAmount" label="优惠金额"></el-table-column>
        <el-table-column prop="paymentMethod" label="支付方式"></el-table-column>
        <el-table-column
          prop="transactionNo"
          label="支付交易号"
          width="250"
        ></el-table-column>
        <el-table-column prop="account" label="采购账号"></el-table-column>
        <el-table-column prop="contactsPhone" label="联系电话"></el-table-column>
        <el-table-column prop="remarks" label="备注"></el-table-column>
        <el-table-column v-if="argument.type === 2" label="编辑">
          <template slot-scope="scope">
            <el-button
              @click="Editorialpurchasing(scope.row, false)"
              type="primary"
              size="mini"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-divider content-position="left"
        ><i class="el-icon-s-goods"></i> 订单备注
        <el-button
          type="success"
          style="margin-left: 20px"
          size="mini"
          @click="OrderProcess()"
          >保存备注</el-button
        ></el-divider
      >
      <el-input
        type="textarea"
        :rows="6"
        placeholder="请输入内容"
        v-model="detialData.remarks"
      >
      </el-input>
      <el-divider content-position="left"
        ><i class="el-icon-s-goods"></i> 操作日志
      </el-divider>
      <!-- 日志内容 -->
      <div>
        <el-descriptions v-for="(item, index) in Logdata" :key="index">
          <el-descriptions-item
            :contentStyle="
              item.colour != null ? { color: item.colour } : { color: '#999' }
            "
          >
            {{ item.addTime + "【" + item.name + "】" + item.info }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <!-- 控制表单 -->
    <el-dialog
      :title="Newpur ? '新增采购信息' : '编辑采购信息'"
      :visible.sync="dialogpurchaseFormVisible"
      :show-close="false"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <!-- 选择乘机人/航段 -->
      <el-form v-if="Newpur" inline :model="passengerData" size="mini">
        <el-form-item label="乘机人">
          <el-select v-model="passengerData.passengerId" multiple>
            <el-option
              v-for="item in detialData.passenger"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="航段">
          <el-select v-model="passengerData.voyage" multiple>
            <el-option
              v-for="item in detialData.voyage"
              :key="item.id"
              :label="item.dep.depPort + item.arr.arrPort + '|' + item.flightNo"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button size="small" type="primary" @click="Confirmpassenger()"
          >确 定</el-button
        >
      </el-form>
      <!-- 编辑/新增表单 -->
      <el-form
        v-else
        inline
        :model="searchForm"
        size="mini"
        :rules="rule"
        ref="searchform"
      >
        <el-form-item label="采购渠道" prop="channel">
          <el-select
            v-model="searchForm.channel"
            filterable
            @change="handleChannelChange"
          >
            <el-option
              v-for="item in purchChannelList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购状态" prop="states">
          <el-select v-model="searchForm.states" filterable>
            <el-option
              v-for="item in PurchaseState"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购单号" prop="purchOrderNo">
          <el-input size="mini" v-model="searchForm.purchOrderNo"></el-input>
        </el-form-item>
        <el-form-item label="支付金额" prop="paymentAmount">
          <el-input
            size="mini"
            @blur="paymentTotaltax()"
            v-model.number="searchForm.paymentAmount"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠金额">
          <el-input size="mini" v-model.number="searchForm.discountAmount"></el-input>
        </el-form-item>
        <el-form-item label="支付方式" prop="paymentMethod">
          <el-select v-model="searchForm.paymentMethod" filterable>
            <el-option
              v-for="item in payMethodList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付交易号" prop="transactionNo">
          <el-input size="mini" v-model="searchForm.transactionNo"></el-input>
        </el-form-item>
        <el-form-item label="采购账号" prop="account">
          <el-select v-model="searchForm.account" filterable>
            <el-option
              v-for="item in PurchaseAccountList"
              :key="item.id"
              :label="item.account"
              :value="item.account"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input size="mini" v-model="searchForm.contactsPhone"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input size="mini" v-model="searchForm.remarks"></el-input>
        </el-form-item>
        <el-form-item label="总税费">
          <el-input
            size="mini"
            @blur="paymentTotaltax()"
            v-model.number="Totaltax"
          ></el-input>
        </el-form-item>
        <!-- 乘机人 -->
        <el-table
          :data="searchForm.detail"
          size="mini"
          style="width: 100%"
          :header-cell-style="{ background: '#eee', color: '#555', margin: '10px 0' }"
        >
          <el-table-column prop="name" label="乘机人" width="150">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.name"> </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="票价" width="150">
            <template slot-scope="scope">
              <el-input size="mini" v-model.number="scope.row.price"> </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="tax" label="税费" width="150">
            <template slot-scope="scope">
              <el-input size="mini" v-model.number="scope.row.tax"> </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="totalAmount" label="总价" width="150">
            <template slot-scope="scope">
              <el-input size="mini" v-model.number="scope.row.totalAmount"> </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="voayge" label="行程" width="150">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.voayge"> </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="ticketNo" label="票号" width="150">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.ticketNo"> </el-input>
            </template>
          </el-table-column>
        </el-table>
        <div class="dialog-footer">
          <el-button size="small" @click="dialogpurchaseFormVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="ConfirmsearchForm()"
            >确 定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  OrderDetails,
  supplyDraftOperate,
  OperateTicket,
  OrderProcessingMethod,
  IssueTickets,
} from "@/api/order/orderint";
import {
  RefundDetails,
} from "@/api/order/refundint";
import { OrderLog } from "@/api/common";
import { decodeFromBase64 } from "@/utils/base64";
export default {
  props: {
    argument: { type: Object },
  },
  data() {
    return {
      Logdata: [],
      description: "暂无内容", //订单为空msg
      showdesc: false,
      detialData: {}, //获取到的详情数据
      decoded: "", // 解码后的结果
      Valpnr: "", //编码原始数据
      dialogpurchaseFormVisible: false, //控制新增采购弹窗
      Newpur: false, //新增需要选择乘机人判断
      searchForm: {}, //提交采购信息
      Totaltax: 0, //税费计算
      passengerData: {
        passengerId: [],
        voyage: [],
      }, //新增需要选择乘机人
      rule: {
        //验证提交表单规则
        channel: [{ required: true, message: "请选择采购渠道！", trigger: "change" }],
        states: [{ required: true, message: "请选择采购状态！", trigger: "change" }],
        purchOrderNo: [{ required: true, message: "请输入采购单号！", trigger: "blur" }],
        paymentAmount: [{ required: true, message: "请输入支付金额!", trigger: "blur" }],
        paymentMethod: [
          { required: true, message: "请选择支付方式！", trigger: "change" },
        ],
        transactionNo: [
          { required: true, message: "请输入支付交易号！", trigger: "blur" },
        ],
        account: [{ required: true, message: "请输入采购账号！", trigger: "change" }],
      },
      //多排订单按钮
      force: false,
      buttonList: [
        "出票完成并回填票号",
        "系统出票",
        "预定编码指令",
        "关闭并解锁",
        "关闭",
      ],
      dialogForce: false, //控制强制弹窗
      Generatelnstructionsvalue: "",
      formattedData: "",
    };
  },
  created() {
    //this.getOrderLog();
    // 组件创建时调用接口
    this.getData();
    //this.$store.dispatch("getPayMethodList");
    //this.$store.dispatch("getPurchChannelList");
    //this.$store.dispatch("getPurchaseStateList");
    //this.$store.dispatch("getQueryAllOfficeList");
    //this.$store.dispatch("getPurchaseAccountList", this.searchForm.channelId); //获取采购账号
  },
  computed: {
    TagItem() {
      return this.$store.state.order.tagitem; //获取数据参数
    },
    isProcess() {
      return this.$store.state.order.isProcessOrder; //判断激活
    },
    tagParameter() {
      return this.$store.state.order.tagParameter; //tag内容数据组
    },
    PurchaseAccountList() {
      //采购账号
      return this.$store.state.pubRequest.allPurchaseAccountList;
    },
    purchChannelList() {
      //采购渠道
      return this.$store.state.pubRequest.allPurchChannelList;
    },
    payMethodList() {
      //支付方式
      return this.$store.state.pubRequest.allPayMethodList;
    },
    PurchaseState() {
      //采购状态
      return this.$store.state.pubRequest.allPurchaseStateList;
    },
    AllOfficeList() {
      //查询Office
      return this.$store.state.pubRequest.allAllOfficeList;
    },
  },
  methods: {
    //进入页面调取数据
    getData() {
      let { orderId, ...rest } = this.argument;
      let p = {
        refundId:orderId,
        ...rest,
        _: Date.now(),
      };
      RefundDetails(p).then((res) => {
        this.showdesc = false;
        let { code, data, msg } = res.data;
        if (code == 0) {
          this.detialData = data;
          /* data.price = data.price
            .filter((item) => item.count !== 0)
            .map((item) => ({
              ...item,
              ...data.total, // 将 data.total 的属性展开并添加到每个 item 中
            }));
          this.setDefaultSelected(); */
        } else {
          this.showdesc = true;
          this.description = msg;
        }
      });
    },
    //解密身份证
    Decrypt(row) {
      supplyDraftOperate({ type: "PrivacyLevel", id: row.id }).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 0) {
          this.decoded = decodeFromBase64(data);
          this.$alert(this.decoded, {
            showClose: false,
            dangerouslyUseHTMLString: true,
          });
          this.$message({ type: "success", message: msg });
        }
      });
    },
    //关闭tag退出页面
    closeTabPage() {
      // 用户点击了确认按钮后执行的代码
      this.TagItem.active = false;
      this.$store.commit("PROCESS_TAG", false);
      const existingTag = this.tagParameter.find(
        (tag) => tag.orderId === this.argument.orderId && tag.type === this.argument.type
      );
      if (existingTag) {
        const index = this.tagParameter.indexOf(existingTag);
        if (index > -1) {
          this.tagParameter.splice(index, 1);
        }
        this.$store.commit("STORAGE_TAG_PARAMETER", this.tagParameter);
      }
    },
    //判断强制弹窗激活
    judgmentPopup(code, msg) {
      if (code == 0) {
        this.$alert(msg, {
          dangerouslyUseHTMLString: true,
          showClose: false,
        }).then(() => {
          this.closeTabPage();
        });
      } else {
        this.$alert(msg, {
          dangerouslyUseHTMLString: true,
          showClose: false,
        });
      }
    },
    //强制编码弹窗激活
    Generatel() {
      let passengerIdsString = this.passengerData.passengerId.join("-");
      OrderProcessingMethod({
        id: this.argument.orderId,
        type: "GenerateInstructions",
        value: this.Generatelnstructionsvalue + "|" + passengerIdsString,
      }).then((res) => {
        let { code, msg, data } = res.data;
        if (code == 0) {
          this.formattedData = data.replace(/\r/g, "\n");
          /* const message = `<div style="max-height: 300px; width: 1200px; overflow-y: auto; padding: 10px" contenteditable="true">${formattedData}</div>`; // 构建 HTML 字符串
            this.$alert(message, '编码', {
              dangerouslyUseHTMLString: true,
              showClose: false,
              width: '1200px'
            }); */
        } else {
          this.$message({ type: "error", message: msg });
        }
      });
    },
    //多排强制按钮
    ForceButton(index) {
      if (index == 0) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        IssueTickets({ orderId: this.argument.orderId, force: this.force }).then(
          (res) => {
            let { code, msg } = res.data;
            loading.close();
            this.judgmentPopup(code, msg);
          }
        );
      } else if (index == 1 || index == 3) {
        let Methodtype = "";
        if (index == 1) {
          Methodtype = "SetSystemTicket";
        } else if (index == 3) {
          Methodtype = "Unlock";
        }
        OrderProcessingMethod({ id: this.argument.orderId, type: Methodtype }).then(
          (res) => {
            let { code, msg } = res.data;
            this.judgmentPopup(code, msg);
          }
        );
      } else if (index == 2) {
        this.dialogForce = true;
      } else if (index == 4) {
        this.closeTabPage();
      }
    },

    // 公共方法，用于处理失焦值的变更和验证
    handleValueChange(row, value, type, reg, errorMsg) {
      if (reg.test(value)) {
        if (value !== this.Valpnr) {
          supplyDraftOperate({ type: type, id: row.id, value: value }).then((res) => {
            if (res.data.code == 0) {
              this.$message({ type: "success", message: res.data.msg });
            } else {
              this.$message({ type: "error", message: res.data.msg });
            }
          });
        } else {
          this.$message({ type: "warning", message: "重复修改" });
        }
      } else {
        this.$message({ type: "warning", message: errorMsg });
      }
    },
    // 聚焦时获取当前值
    focusPnr(val) {
      this.Valpnr = val;
    },
    // 失焦时检查PNR值
    checkPnr(row) {
      const pnrReg = /^[A-Z0-9]{6}$/; // 示例正则表达式，6位大写字母或数字
      const pnrErrorMsg = "该值由6位大写字母或数字组成！";
      this.handleValueChange(row, row.pnr, "ModifyPnr", pnrReg, pnrErrorMsg);
    },

    // 失焦时检查检验票号
    checkTicketNo(row) {
      const ticketNoReg = /^\d{13}$/; // 示例正则表达式，13位数字
      const ticketNoErrorMsg = "票号由13位数字组成！";
      this.handleValueChange(
        row,
        row.ticketNo,
        "ModifyTicketNo",
        ticketNoReg,
        ticketNoErrorMsg
      );
    },
    //打开新增采购弹窗
    Newpurchase(val) {
      this.Newpur = val;
      this.dialogpurchaseFormVisible = true;
    },
    //获取全选this.passengerData的id
    setDefaultSelected() {
      if (this.detialData.passenger.length > 0) {
        this.passengerData.passengerId = this.detialData.passenger.map((item) => item.id);
      }
      if (this.detialData.voyage.length > 0) {
        this.passengerData.voyage = this.detialData.voyage.map((item) => item.id);
      }
    },
    //确定选中乘机人/航班
    Confirmpassenger() {
      // 重置searchForm对象
      this.searchForm = {
        account: "",
        accountId: null,
        channel: "",
        channelId: null,
        contactsPhone: "",
        detail: [],
        discountAmount: 0,
        id: 0,
        orderId: this.argument.orderId,
        paymentAccountId: null,
        paymentAmount: null,
        paymentMethod: "",
        purchOrderNo: "",
        remarks: "",
        state: 2,
        states: "已支付",
        transactionNo: "",
      };

      // 获取乘机人ID数组和航段ID数组
      const passengerIds = this.passengerData.passengerId;
      const voyageIds = this.passengerData.voyage;

      // 遍历每个乘机人ID
      passengerIds.forEach((passengerId, passengerIndex) => {
        // 查找对应的乘机人对象
        const passenger = this.detialData.passenger.find((p) => p.id === passengerId);
        if (passenger) {
          // 遍历每个航段ID
          voyageIds.forEach((voyageId, voyageIndex) => {
            const voyage = this.detialData.voyage.find((v) => v.id === voyageId);
            if (voyage) {
              this.searchForm.detail.push({
                id: passengerIndex * voyageIds.length + voyageIndex, // 计算detail中的唯一ID
                voyageId: voyageId,
                passengerId: passenger.id,
                name: passenger.name,
                price: null,
                tax: null,
                totalAmount: null,
                // 使用航段的具体信息
                voayge: voyage.dep.depPort + voyage.arr.arrPort + "|" + voyage.flightNo,
                ticketNo: "",
              });
            }
          });
        }
      });
      // 关闭表单
      this.Newpur = false;
    },
    //支付金额计算
    paymentTotaltax() {
      const tax = this.Totaltax / this.searchForm.detail.length;
      const price = this.searchForm.paymentAmount - tax;
      this.searchForm.detail.forEach((item, index) => {
        this.searchForm.detail[index].price = price;
        this.searchForm.detail[index].tax = tax;
        this.searchForm.detail[index].totalAmount = price + tax;
      });
    },
    //获取采购订单
    Editorialpurchasing(row, jud) {
      this.Newpur = jud;
      (this.dialogpurchaseFormVisible = true), (this.searchForm = row);
    },
    //采购订单id赋值 获取替换采购账号列表
    handleChannelChange() {
      this.purchChannelList.forEach((sh) => {
        if (sh.name == this.searchForm.channel) {
          this.searchForm.channelId = sh.id;
        }
      });
      this.$store.dispatch("getPurchaseAccountList", {
        channel: this.searchForm.channelId,
      });
    },
    //确认新增加/编辑提交
    ConfirmsearchForm() {
      this.PurchaseAccountList.forEach((sh) => {
        if (sh.account == this.searchForm.account) {
          this.searchForm.accountId = sh.id;
        }
      });
      this.payMethodList.forEach((sh) => {
        if (sh.name == this.searchForm.paymentMethod) {
          this.searchForm.paymentAccountId = sh.id;
        }
      });
      this.PurchaseState.forEach((sh) => {
        if (sh.name == this.searchForm.states) {
          this.searchForm.state = sh.id;
        }
      });
      this.$refs.searchform.validate((valid) => {
        if (valid) {
          OperateTicket(this.searchForm).then((res) => {
            if (res.data.code == 0) {
              this.$message({ type: "success", message: res.data.msg });
              this.getData();
              this.dialogpurchaseFormVisible = false;
            } else {
              this.$message({ type: "warning", message: res.data.msg });
            }
          });
        } else {
          return false;
        }
      });
    },
    //保存备注
    OrderProcess() {
      let p = {
        id: this.argument.orderId,
        type: "SaveRemarks",
        value: this.detialData.remarks,
      };
      supplyDraftOperate(p).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 0) {
          this.$message({ type: "success", message: msg });
        } else {
          this.$message({ type: "warning", message: msg });
        }
      });
    },
    //订单日志
    getOrderLog() {
      let p = {
        orderId: this.argument.orderId,
        type: 1,
      };
      OrderLog(p).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 0) {
          this.Logdata = data;
          if (this.argument.type == 2) {
            let popupItems = this.Logdata.filter((item) => item.popup); // 过滤出所有popup为true的项
            let popupContent = popupItems
              .map(
                (item) =>
                  `<div style="color: ${item.colour};">${
                    item.addTime + item.name + item.info
                  }</div>`
              )
              .join("");
            if (popupItems.length !== 0) {
              this.$alert(
                `<div style="max-height: 400px; overflow-y: auto;">${popupContent}</div>`,
                "操作日志",
                {
                  dangerouslyUseHTMLString: true,
                  showClose: false,
                }
              );
            }
          }
        }
      });
    },
    objectSpanMethod({ columnIndex, rowIndex }) {
      if (columnIndex >= 5) {
        // 假设第6列的索引是5
        if (rowIndex % this.detialData.price.length === 0) {
          return {
            rowspan: this.detialData.price.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
  watch: {
    // 监听 PurchaseAccountList 计算属性的变化
    PurchaseAccountList(newValue) {
      console.log("采购账号列表更新了", newValue);
    },
    // 监听 tag参数变化重新刷新 计算属性的变化
    TagItem(newValue) {
      if (newValue) {
        this.getData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order-detials {
  .content {
    flex: 1;
    overflow-y: scroll;

    /deep/.el-page-header {
      height: 24px;

      .el-page-header__content {
        font-size: 14px;
      }
    }

    .el-table {
      border-top: 1px solid #ebeef5;
    }

    /deep/.el-divider__text {
      font-size: 16px;
      font-weight: 600;
      color: royalblue;
      background: #f0f2f5;
    }

    .el-row {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #909399;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .dialog-footer {
    margin-top: 20px;
  }

  /deep/.el-message-box__content {
    height: 100px !important;
    overflow-y: auto !important;
    padding: 10px 15px;
  }

  .drawbutton {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
