export default {
    state: {
        tagParameter: [],
        tagitem: {},
        isProcessOrder: false,
        isPage:[]
    },
    mutations: {
        STORAGE_TAG_PARAMETER(state, data) {
            state.tagParameter = data;
        },
        MEMORY_TAG(state, val) {
            state.tagitem = val;
        },
        PROCESS_TAG(state, val) {
            state.isProcessOrder = val;
        },
        PAGE_IS(state, val) {
            state.isPage = val;
        },

    },

}