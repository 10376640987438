import Vue from 'vue'
import Vuex from 'vuex'
import navs from './navs'
import system from './system'
import order from './order'
import pubRequest from './publicRequest'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    navs,
    system,
    order,
    pubRequest
  }
})
