<template>
  <div class="menu-tags">
    <el-tag v-for="tag in tagList" :key="tag.path" size="small" :closable="isAffix(tag) ? false : true"
      @click="handleTagClick(tag)" @close="handleClose(tag)">
      <router-link class="tags-item" :to="tag.path" @contextmenu.prevent.native="openMenu(tag, $event)">
        {{ tag.title }}
      </router-link>
    </el-tag>
    <!-- 详情页传递的tag -->
    <el-tag size="small" @contextmenu.prevent.native="openMenu(item, $event)" closable
      v-for="(item, index) in DetailsTagList" :key="index" :type="item.active ? 'success' : 'info'"
      @click="handleDetailsTag(item)" @close="handleCloseTag(item)">
      {{ item.type == 1 ? '查看详情' :item.type == 2? '处理订单': item.type == 3? '返单处理':''}}
    </el-tag>

    <ul class="menu-context" v-show="visible" :style="{ top: top + 'px', left: left + 'px' }">
      <li v-if="!isAffix(selectedTag)" @click="handleClose(selectedTag)">关闭当前</li>
      <li @click="closeOthersTags(selectedTag)">关闭其他</li>
      <li @click="closeAllTags()">关闭全部</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false, //标签右键卡片是否显示
      top: 0,  //标签右键卡片位置
      left: 0,
      tagList: [{ path: '/plat/notice', title: '平台公告', affix: true }],
      fixdTag: {
        path: '/plat/notice',
        title: '平台公告',
        affix: true
      },
      selectedTag: {},
      isCurrent: false
    }
  },
  //获取新tag
  computed: {
    DetailsTagList() {
      return this.$store.state.order.tagParameter
    },
    TagItem() {
      return this.$store.state.order.tagitem  //获取数据参数
    },
  },
  created() {
    this.addTag(this.$route)
  },
  methods: {
    initNewTags() {
      //计算新标签
      const tagList = this.DetailsTagList;
      const tagItem = this.TagItem;
      tagList.forEach(tag => {
        tag.active = false;
      });
      const matchedTag = tagList.find(tag => tag.orderId === tagItem.orderId && tag.type === tagItem.type);
      if (matchedTag) {
        matchedTag.active = true;
      }
      // 提交mutation更新Vuex state
      this.$store.commit('STORAGE_TAG_PARAMETER', tagList);
      this.$store.commit('PROCESS_TAG', true); // 更新PROCESS_TAG状态
    },
    //切换新标签
    handleDetailsTag(item) {
      item.active = true
      this.$store.commit('MEMORY_TAG', item);
      this.$store.commit('PROCESS_TAG', true);
      
    },
    //点击关闭新标签
    handleCloseTag(item) {
      if (this.DetailsTagList.length == 1) {
        this.$store.commit('PROCESS_TAG', false); // 更新PROCESS_TAG状态
      }
      // 过滤掉被关闭的标签
      const updatedTagList = this.DetailsTagList.filter(tag => tag !== item);
      // 下一个激活的标签是数组中的最后一个标签
      if (this.DetailsTagList.find(tag => tag.active)) {
        const nextActiveTag = updatedTagList[updatedTagList.length - 1];
        this.$store.commit('MEMORY_TAG', nextActiveTag);
      }
      this.$store.commit('STORAGE_TAG_PARAMETER', updatedTagList);
    },
    //是否固定
    isAffix(tag) {
      return tag.affix
    },
    //计算标签
    addTag(tag) {
      this.visible = false;
      if (this.tagList.some(v => v.path === tag.path)) return
      this.tagList.push(
        {
          path: tag.path,
          title: tag.meta.title,
          affix: false
        }
      )
    },
    //关闭标签
    handleClose(tag) {
      this.visible = false; //关闭标签右键卡片
      this.tagList = this.tagList.filter(item => item.path != tag.path)
      let index = this.tagList.length - 1;  //计算最后一个标签的index
      let current = this.tagList[index];  //获取最后一个标签
      this.$router.push(current.path);  //路由跳转到当前标签
      this.handleCloseTag(tag)//当前新标签也关闭
    },
    handleTagClick(tag) {
      this.$store.commit('PROCESS_TAG', false);
      this.DetailsTagList.forEach(tag => {
        tag.active = false;
      });
      this.$router.push(tag.path)
    },
    // 打开右键卡片
    openMenu(tag, e) {
      this.left = e.clientX - 25
      this.top = e.clientY + 15
      this.visible = true
      this.selectedTag = tag
    },
    //关闭其他标签
    closeOthersTags(tag) {
      this.visible = false;
      if(tag.path == '/plat/notice') {
        this.tagList = [{ path: '/plat/notice', title: '平台公告', affix: true }]
      } else {
        let curTag = this.tagList.filter(item => item.path == tag.path)
        this.tagList = [
          this.fixdTag,
          ...curTag
        ]
      }
      this.$router.push(tag.path)
      //关闭其他新标签
      const updatedTagList = this.DetailsTagList.filter(item => item == tag);
      this.$store.commit('STORAGE_TAG_PARAMETER', updatedTagList);
    },
    //关闭所有标签
    closeAllTags() {
      this.$store.commit('STORAGE_TAG_PARAMETER', []);
      this.$store.commit('PROCESS_TAG', false);  //所有新标签也关闭
      this.tagList = [{ path: '/plat/notice', title: '平台公告', affix: true }]
      this.$router.push('/plat/notice')
    },
  },
  //侦听器
  watch: {
    //监听路由变化 ，获取路由标签
    "$route.path"(val) {
      this.$store.commit('PROCESS_TAG', false);
      this.DetailsTagList.forEach(tag => {
        tag.active = false;
      });
      this.addTag(this.$route)
    },
    //监听newtag数组变化
    TagItem(newValue) {
      // 在这里处理你的逻辑
      if (newValue) {
        this.initNewTags()
      }
    },
  }

}
</script>

<style lang="less" scoped>
.el-tag {
  margin-left: 10px;
  margin-top: 5px;
  background: #fff;
}

a {
  color: #000 !important;
  text-decoration: none;
}

.router-link-active {
  color: #409eff !important;
  text-decoration: none;
  position: relative;
}

.tags-item {
  position: relative;
}

.menu-context {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}
</style>