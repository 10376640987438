/**
 * @file 退票管理 - 退票管理接口
 * @date 2024.1.6
 */

import request from '@/utils/request';
//获取退票订单列表
export function getQueryRefundList(data) {
    return request({
      url: '/RefundInt/QueryRefundList',
      method: 'post',
      data,
      headers: {
        "Content-Type":"application/x-www-form-urlencoded"
      }
    })
  }    
  //查看/处理退票订单
export function RefundDetails(data) {
    return request({
      url: '/RefundInt/RefundDetails',        
      method: 'post',  
      data,
      headers: {
        "Content-Type":"application/x-www-form-urlencoded"   
      } 
    })
  }
  