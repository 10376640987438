/**
 * @file 订单管理 - 改期订单
 * @date 2025.01.03
 */

import request from '@/utils/request'; // 引入封装好的axios

//获取订单数据
export function getChangeDateList(data) {
  return request({
    url: '/ChangeInt/QueryChangeList',
		method: 'post',
		data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}

//处理订单
export function changeDetails(data) {
  return request({
    url: '/ChangeInt/ChangeDetails',
		method: 'post',
		data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}

