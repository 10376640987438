import { render, staticRenderFns } from "./RescheduleDetail.vue?vue&type=template&id=fbcb1992&scoped=true"
import script from "./RescheduleDetail.vue?vue&type=script&lang=js"
export * from "./RescheduleDetail.vue?vue&type=script&lang=js"
import style0 from "./RescheduleDetail.vue?vue&type=style&index=0&id=fbcb1992&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbcb1992",
  null
  
)

export default component.exports