<template>
  <div class="reschedule-detail">
    <div class="header">
      <div>店铺平台：{{ detailData.shop }}</div>
      <div>订单号：{{ detailData.orderNo }}</div>
      <div>政策代码：{{ detailData.policyCode }}</div>
      <div>改期类型：{{ detailData.changeType }}</div>
      <div>平台进单时间：{{ detailData.platformAddTime }}</div>
    </div>
    <el-divider content-position="left"><i class="el-icon-s-promotion"></i> 航班信息</el-divider>
    <el-table :data="detailData.voyage" size="mini" style="width: 100%" :header-cell-style="{ background: '#eee', color: '#555' }">
      <el-table-column prop="flightNo" label="航班号"></el-table-column>
      <el-table-column prop="cabin" label="舱位"></el-table-column>
      <el-table-column prop="dep" label="出发"></el-table-column>
      <el-table-column prop="arr" label="到达"></el-table-column>
      <el-table-column prop="depTime" label="起飞时间"></el-table-column>
      <el-table-column prop="arrTime" label="到达时间"></el-table-column>
    </el-table>
    <el-divider content-position="left"><i class="fa fa-user-circle"></i> 乘客信息</el-divider>
    <el-table :data="detailData.passenger" size="mini" style="width: 100%" :header-cell-style="{ background: '#eee', color: '#555' }">
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="passengerType" label="乘机人类型"></el-table-column>
      <el-table-column prop="certificateType" label="证件类型"></el-table-column>
      <el-table-column prop="certificateNumber" label="证件号"></el-table-column>
      <el-table-column prop="ticketNo" label="票号"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { changeDetails } from '@/api/reschedul/order';
  export default {
    props: {
      argument: { type: Object },
    },
    data() {
      return {
        detailData: {}
      }
    },
    created() {
      this.getDetailData()
    },
    methods: {
      getDetailData() {
        let params = {
          changeId: this.argument.orderId,
          type: this.argument.type,
          _: Date.now()
        }
        changeDetails(params).then(res => {
          if(res.data.code === 0) {
            this.detailData = res.data.data
          }
          
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #909399;
  }
  /deep/.el-divider__text {
    font-size: 16px;
    font-weight: 600;
    color: royalblue;
    background: #f0f2f5;
  }
</style>