<template>
  <div class="login-page">
    <div class="login-bg"></div>
    <div class="login-main">
      <div class="login-box">
        <h1 class="title">扫码登录</h1>
        <div class="qr">
          <img :src="url" width="100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginMethod, checkCode, retrieveSession } from '@/api/login';
import local from '@/utils/local';
export default {
  data() {
    return {
      url: '',  //二维码地址
      key: '',  //获取扫码状态时参数
      timer: null
    }
  },
  created() {
    this.getLoginCode()
    this.redirectLogin()
  },
  methods: {
    getLoginCode() {
      loginMethod().then(res => {
        let { code, data } = res.data
        if(code == 0) {
          this.url = 'data:image/png;base64,' + data.qr
          this.key = data.key
          
        }
      })
    },
    checkScanStatus() {
      let params = {
        _: Date.now()
      }
      checkCode(params, this.key).then(res => {
        let { data, code, msg } = res.data;
        if(code == 0) {
          clearInterval(this.timer)
          local.set('token', data.key)
          local.set('userInfo', data)
          this.$router.push({path: '/plat/notice'})
        } else if(code > 1) {
          this.getLoginCode()
          this.$message({type: 'error', message: msg})
        }
      })
    },
    //免登录
    redirectLogin() {
      let userInfo = local.get('userInfo');
      
      //如果token存在，且在token在有效期内
      if(userInfo && new Date() < new Date(userInfo.expires)) {
        let params = {
          watermark: userInfo.watermark,
          _: Date.now()
        }
        retrieveSession(params).then(res => {
          if(res.data.code == 0) {
            this.$router.push({path: '/plat/notice'})
          } else {
            local.clear()
            this.timer = setInterval(this.checkScanStatus, 3000);
          }
        })
      } else {
        this.timer = setInterval(this.checkScanStatus, 3000);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
}
.login-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("@/assets/imgs/bg.jpg") no-repeat;
  background-size: cover;
  /* 设置背景图片固定，不随滚动条滚动 */
  background-attachment: fixed;
  /* 应用模糊效果 */
  filter: blur(9px);
  /* 设置模糊效果应用的区域 */
  background-blend-mode: normal;
  /* 确保模糊效果应用在背景上 */
  z-index: -1;
}
.login-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .login-box {
    width: 350px;
    height: 400px;
    background-color: #fff;
    box-shadow: 0 0 20px #000000;
    .title {
      color: #000;
      font-size: 20px;
      text-align: center;
    }
    .qr {
      width: 300px;
      height: 300px;
      background-color: #fff;
      margin-left: 25px;
    }
  }
}
</style>