<template>
  <div class="left-aside">
    <div class="left-logo">
      <img src="../../assets/imgs/logo.jpg" width="50px">
      <span v-show="!isCollapse">成都七天国际系统</span>
    </div>
    <el-menu
      router
      :unique-opened="true"
      :collapse="isCollapse"
      :default-active="curActive"
      class="el-menu-vertical-demo">
      <el-menu-item index="/plat/notice">
        <i class="fa fa-volume-up"></i>&nbsp;
        <span>平台公告</span>
      </el-menu-item>
      <el-submenu v-for="(menu, i) in menuList" :index="menu.path" :key="menu.path + i" :hidden="menu.hidden">
          <template slot="title">
            <i :class="menu.meta.icon"></i>&nbsp;
            <span>{{menu.meta.title}}</span>
          </template>
          <el-menu-item v-for="item in menu.children" :key="item.path" :index="item.path">
            <template slot="title">
              <i :class="item.meta.icon"></i>&nbsp;
              <span>{{item.meta.title}}</span>
            </template>
          </el-menu-item>
        </el-submenu>
    </el-menu>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isCollapse: false,  //导航是否折叠
        staticRouter: [],
      }
    },
    computed: {
      menuList() {
        return this.$store.state.navs.dynamicRouter
      },
      //当前激活导航
      curActive() {
        return this.$route.path
      },
    },
    created() {
      //解决刷新时，显示隐藏的文字
      this.isCollapse = document.body.clientWidth > 1000 ? false : true;

      //监听窗口大小变化,大于1000，显示导航文字，否则隐藏
      window.addEventListener("resize", () => {
        let w = document.body.clientWidth;
        if (w < 1000) {
          this.isCollapse = true;
        } else {
          this.isCollapse = false;
        }
      });
      //接收头部传递过来的状态
      this.$bus.$on("changeCollapse", (data) => {
        this.isCollapse = data;
      });
    }
  }
</script>

<style lang="less" scoped>
.left-logo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 700;
}
.el-menu {
    border-right: 0px;
    .el-menu-item {
      padding: 0;
      min-width: 0;
    }
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
  }
</style>