/**
 * @file 公共接口请求
 * @date 2024.12.10
 * @description 由于使用文件较多，公共接口均在Layout组件进行统一调用
 */
import { getAllAirCompany, getAllOta, getAllShop, getAllPaymentPlat, getAllPurchChannel, 
  getAllPayMethod,getAllPurchaseAccount,PurchaseState,getQueryAllOffice } from "@/api/common";
export default {
  state: {
    allCarrierList: [], //航司3
    allOtaList: [], // 平台
    allShopList: [],  //店铺
    allPayPlatList: [], // 支付平台
    allPurchChannelList: [], //采购渠道 7
    allPayMethodList: [], //支付方式 9
    allPurchaseAccountList:[],//采购账号8
    allPurchaseStateList:[],//采购状态
    allAllOfficeList:[]//查询Office

  },
  mutations: {
    GET_ALL_CARRIER(state, data) {
      state.allCarrierList = data;
    },
    GET_ALL_OTA(state,data) {
      state.allOtaList = data
    },
    GET_ALL_SHOP(state,data) {
      state.allShopList = data
    },
    GET_ALL_PAYPLAT(state,data) {
      state.allPayPlatList = data
    },
    GET_ALL_PURCH(state,data) {
      state.allPurchChannelList = data
    },
    GET_ALL_PAY_METHOD(state,data) {
      state.allPayMethodList = data
    },
    GET_ALL_PURCHASE_ACCOUNT(state,data) {
      state.allPurchaseAccountList= data
    },
    GET_PURCHASE_STATE(state,data) {
      state.allPurchaseStateList= data
    },
    GET_ALL_OFFICE(state,data) {
      state.allAllOfficeList= data
    }
  },
  actions: {
    getAirCompanyList({ commit }, obj) {
      getAllAirCompany(obj).then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_CARRIER', res.data.data)
        }
      })
    },
    getOtaList({commit}, obj) {
      getAllOta(obj).then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_OTA', res.data.data)
        }          
      })
    },
    getShopList({commit}) {
      getAllShop().then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_SHOP', res.data.data)
        }          
      })
    },
    getPayPlatList({commit}) {
      getAllPaymentPlat().then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_PAYPLAT', res.data.data)
        }          
      })
    },
    getPurchChannelList({commit}) {
      getAllPurchChannel().then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_PURCH', res.data.data)
        }          
      })
    },
    getPayMethodList({commit}, obj) {
      getAllPayMethod(obj).then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_PAY_METHOD', res.data.data)
        }          
      })
    },
    getPurchaseAccountList({commit},val) {
      getAllPurchaseAccount(val).then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_PURCHASE_ACCOUNT', res.data.data)
        }          
      })
    },
    getPurchaseStateList({commit},val) {
      PurchaseState(val).then(res => {
        if(res.data.code == 0) {
          commit('GET_PURCHASE_STATE', res.data.data)
        }          
      })
    },
    getQueryAllOfficeList({commit},val) {
      getQueryAllOffice(val).then(res => {
        if(res.data.code == 0) {
          commit('GET_ALL_OFFICE', res.data.data)
        }          
      })
    },
  },
  
}