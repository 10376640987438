<template>
  <div class="watermark" :style="{ backgroundImage: watermark }">
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Watermark',
    },
    rotate: {
      type: Number,
      default: -35,
    },
  },
  data() {
    return {
      watermark: '',
    };
  },
  mounted() {
    this.generateWatermark();
  },
  methods: {
    generateWatermark() {
      const canvas = document.createElement('canvas');
      const wm = document.querySelector('.watermark')
      const ctx = canvas.getContext('2d');

      canvas.width = 300;
      canvas.height = 200;

      ctx.font = `18px Arial`;
      ctx.fillStyle = 'rgba(9, 9, 9, 0.04)';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.translate(100, 100);
      ctx.rotate((this.rotate * Math.PI) / 180);
      ctx.fillText(this.text, 0, 0);
      
      this.watermark = `url(${canvas.toDataURL('image/png')}), url(${canvas.toDataURL('image/png')})`;
    },
  },
};
</script>

<style>
.watermark {
  position: fixed;
  width: 100%;
  height: 100%;
  user-select: none;  /** 用户不可选择 */
  -webkit-user-select: none;
  pointer-events: none; /** 确保水印不会阻挡页面的点击事件 */
  background-repeat: repeat;
  background-position: 150px 100px, 0 0;
  z-index: 3000;
}
</style>
