/**
 * @file 订单管理 - 待处理管理接口
 * @date 2024.11.27
 */

import request from '@/utils/request';
//获取待处理订单列表
export function getQueryPendingOrderList(data) {
    return request({
      url: '/OrderInt/QueryPendingOrderList',
      method: 'post',
      data,
      headers: {
        "Content-Type":"application/x-www-form-urlencoded"
      } 
    })
  }

//获取待处理订单详情
export function OrderDetails(data) {
  return request({
    url: '/Orderint/OrderDetails',
    method: 'post',
    data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}
/* 处理订单修改编码票号 *///保存备注
export const supplyDraftOperate = (data) => {
  return request({
    url: '/Orderint/OrderProcessingMethod',
    method: 'post',
    data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}
/* 处理采购信息新增和编辑 */
export const OperateTicket = (data) => {
  return request({
    url: '/Orderint/OperateTicketing',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/json'
    }
    
  })
}
//获取订单查询列表
export function QueryOrderList(data) {
  return request({
    url: '/OrderInt/QueryOrderList',
    method: 'post',
    data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}
//解锁订单/强解订单/取消订单/系统出票/关闭并解锁
export function OrderProcessingMethod(data) {
  return request({
    url: '/OrderInt/OrderProcessingMethod',
    method: 'post',
    data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}
//出票完成并回填票号
export function IssueTickets(data) {
  return request({
    url: '/OrderInt/IssueTickets',
    method: 'post',
    data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}




