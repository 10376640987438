import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import Login from '@/views/login/Login.vue';
import Layout from '@/views/layout/Layout.vue';
import local from '@/utils/local';

//重写路由的push，解决跳转当前导航的警告
const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location){
  return originPush.call(this,location).catch(err=>err)
} 

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login' // 直接重定向
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: "/plat",
    component: Layout,
    name: "notice",
    redirect: '/plat/notice',
    meta: { title: "平台公告", icon: "fa fa-volume-up", roles: null },
    children: [
      {
        path: "/plat/notice",
        component:  () => import('@/views/plat-notice/PlatNotice.vue'),
        name: "notice",
        meta: { title: "平台公告", icon: "fa fa-volume-down", roles: null }
      }
    ],
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let tk = local.get('token')
  if(tk) {
    store.dispatch('getRouters')
    next()
  } else {
    if(to.path == '/login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
