/**
 * @file 公共接口函数
 * @date 2024.11.25
*/

import request from '@/utils/request'

//菜单查询
export function getMenus(data) {
  return request({
    url: '/Menu/Get',
    method: 'post',
    data
  })
}

//部门Id查询
export function getRoles(data) {
  return request({
    url: '/Cmmn/QueryAllRole',
    method: 'post',
    data
  })
}

// 航司查询
export function getAllAirCompany(data) {
  return request({
    url: '/Cmmn/QueryAllAirlineCompany',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}
// 平台查询
export function getAllOta(data) {
  return request({
    url: '/Cmmn/QueryAllOta',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}
// 店铺查询
export function getAllShop(data) {
  return request({
    url: '/Cmmn/QueryAllShop',
    method: 'post',
    data
  })
}

// 支付平台查询
export function getAllPaymentPlat(data) {
  return request({
    url: '/Cmmn/QueryAllPaymentPlatform',
    method: 'post',
    data
  })
}

// 出票渠道
export function getAllPurchChannel(data) {
  return request({
    url: '/Cmmn/QueryAllPurchaseChannel',
    method: 'post',
    data
  })
}

// 支付方式
export function getAllPayMethod(data) {
  return request({
    url: '/Cmmn/QueryAllPaymentMethod',
    method: 'post',
    data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}
//查询采购账号
export function getAllPurchaseAccount(data) {
  return request({
    url: '/Cmmn/QueryAllPurchaseAccount',
    method: 'post',
    data,
    headers: {
      "Content-Type":"application/x-www-form-urlencoded"
    }
  })
}

//添加状态
export const PurchaseState = (data) => {
  return request({
    url: '/Cmmn/QueryAllOrderPurchaseState',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded'
    }
  })
}
//订单日志
export const OrderLog = (data) => {
  return request({
    url: '/Cmmn/QueryOrderLog',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded'
    }
  })
}
//查询Office
export const getQueryAllOffice = (data) => {
  return request({
    url: '/Cmmn/QueryAllOffice',
    method: 'post',
    data,
  })
}


