<template>
  <div class="layout-page">
    <left-nav></left-nav>
    <div class="right">
      <right-header></right-header>
      <div class="exit">
        <keep-alive>
          <router-view v-if="!isProcess"></router-view>
        </keep-alive>
        <template v-for="(item, i) in tagParameter">
          <component :argument="item" v-if="item.active" :key="i" v-bind:is="item.currentTabComponent"></component>
        </template>
      </div>
    </div>
    <watermark :text="watermark"></watermark>
  </div>
</template>

<script>
import Watermark from '@/components/Watermark.vue';
import LeftNav from './LeftNav.vue';
import RightHeader from './RightHeader.vue';
import local from '@/utils/local';
import Orderdetails from '@/components/order/Orderdetails.vue';
import RefundDetails from '@/components/refund/RefundDetails.vue';
import RescheduleDetail from '@/components/reschdule/RescheduleDetail.vue';
export default {
  components: {
    Watermark,
    LeftNav,
    RightHeader,
    Orderdetails,
    RefundDetails,
    RescheduleDetail
  },
  data() {
    return {
      watermark: '',
      currentTabComponent: 'Orderdetails'
    }
  },
  computed: {
    TagItem() {
      return this.$store.state.order.tagitem  //获取数据参数
    },
    isProcess() {
      return this.$store.state.order.isProcessOrder //判断激活
    },
    isPage() {
      return this.$store.state.order.isPage//判断是否有详情数据
    },
    tagParameter() {
      return this.$store.state.order.tagParameter //tag内容数据组
    }
  },
  created() {
    this.watermark = local.get('userInfo').watermark
  },
}
</script>

<style lang="less" scoped>
.layout-page {
  display: flex;
  height: 100%;

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 89%;

    .exit {
      flex: 1;
      padding: 16px;
      overflow-y: auto;
      background-color: #F0F2F5;
    }

  }

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 7px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);
    background : #74baff;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #fff;
  }
}
</style>